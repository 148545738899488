@import './colors.scss';

.navbar{
  min-height: 50px;
}

.right-login {
  text-align: right;
  padding: 20px;
}

.img-cover {
  display: block;
  margin-left: auto;
  margin-right: auto;
  object-fit: cover;
  height: 300px;
  max-width: 100%;
  min-height: 200px;
  max-height: 300px;
}

.cover-right {
  margin-top: 170px;
}

.line {
  border-style: solid;
  border-width: 0.8px;
  border-color: #979797;
}

.padding-zero {
  padding: 0px
}

.margin-50 {
  margin-left: 50px
}

.button-default {
  color: $tcn-main-blue !important;
  border-color: $tcn-main-blue !important
}

@media (max-width: 800px) {
  .img-cover {
    height: 150px;
    width: 100%;
  }
}

.footer-css{
  background-color: #d8d8d8;
  border: 2px solid #979797;
  margin-top: 90px;
  text-align: center;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
}

.footer-letters{
  padding-top: 21px;
  padding-bottom: 15px;
}

.footer-single-letter{
  margin-bottom: 5px !important;
  color: #5e5f5e;
}
