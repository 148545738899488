@import './colors.scss';

.label-company {
  margin-bottom: 8px;
  text-align: start;
  font-size: 14px;
}

.label-login {
  padding-left: 15px;
}

.label-pass {
  color: $tcn-blue-login !important;
}

.center {
  text-align: center
}

.right {
  text-align: right
}

.forget-password {
  margin-left: 35px;
  margin-top: 10px;
  cursor: pointer;
  color: $tcn-blue-login !important;
  background-color: transparent;
  border-color: transparent;
  padding: 0;
}

.forget-password2 {
  margin-left: 115px;
  margin-top: 10px;
  margin-bottom: 20px;
  cursor: pointer;
  color: $tcn-title-blue !important;
  font-size: 15px;
  background-color: transparent;
  border-color: transparent;
  padding: 0;
}

.login-title-company {
  font-size: 22px;
  font-family: 'Ubuntu';
  color: var(--haunter-purple);
  font-weight: 500;
  text-align: center;
  margin-bottom: 40px;
}

.boton-login-tcn {
  font-size: 22px;
  color: #FFFFFF;
  font-weight: 500;
  text-align: center;
  margin-bottom: 40px;
  background: var(--haunter-purple);
}

.login-ask-company {
  font-size: 22px;
  color: $tcn-blue-login;
  font-weight: 500;
  text-align: left;
  margin-left: 20px;
  margin-bottom: 20px;
  margin-top: 25px;
}

.login-remember {
  margin-left: 5px;
}

.login-remember {
  margin-left: 5px;
}

.remember {
  margin-top: 1rem;
  margin-left: 1px !important;
}

.footer-button {
  margin-bottom: 1rem;
  margin: auto;
}

@media (max-width: 800px) {
  .login-company {
    cursor: pointer;
    padding-right: 30px;
  }

  .navbar-text {
    font-size: 20px
  }
}

.login-registrame {
  margin-top: 20px;
  color: var(--haunter-purple);
  font-size: 26px;
  font-weight: 500;
  font-family: 'Ubuntu';
}

.form-title_registrame {
  text-align: center;
  margin-bottom: 30px;
}

.ter_y_con{
  margin-bottom: 15px;
  color: #9b9b9b;
}

.phone-number {
  &:focus-within {
    border-color: #6344DD;
    box-shadow: 0 0 0 1px var(--haunter-purple);
  }
  .PhoneInputInput, .honeInputInput:focus-visible {
    border: 0;
    outline: 0;
    height: 24px;
  }
}