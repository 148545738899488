@import './colors.scss';

.space-down {
  margin-bottom: 19px;
}

.form-title {
  text-align: center;
  margin-bottom: 30px;
}

.tcn-label-register{
  font-size: 14px;
  margin-bottom: 2px;
}

.phone-number {
  &:focus-within {
    border-color: #6344DD;
    box-shadow: 0 0 0 1px var(--haunter-purple);
  }
  .PhoneInputInput, .honeInputInput:focus-visible {
    border: 0;
    outline: 0;
    height: 24px;
  }
}