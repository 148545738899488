$tcn-color-blue: #4990e0;
$tcn-breadcrumb-green: #8CB00E;
$tcn-breadcrumb-gray:#777777;
$tcn-title-blue:#0374ba;
$tcn-p-gray:#5d5d5d;
$tcn-icon-view-end-gray: #9a9a9a;
$tcn-view-end-line-gray: #e7e7e7;
$tcn-modal-jumbotron-gray: #eeeeee;
$tcn-main-blue: #007bff;
$tcn-error-red: red;
$tcn-blue-login: #6344dd;
$tcn-gray-label: #4a4a4a;
$tcn-blue-button-hover: #4a90e2;
$tcn-blue-button: #0274be;
$tcn-green-button: #8CB00E;
$tcn-green-button-hover: #8DC740;
