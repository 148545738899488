@import 'colors.scss';

.breadcrumb {
  margin-top: 1em !important;
}

.tcn-cargo-span {
  width: 432px;
  height: 31px;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
  color: var(--haunter-purple);
  flex: none;
  order: 0;
  flex-grow: 0;
}

.tcn-region-p {
  font-size: 17px;
  color: #7C8DB0;;
  margin-top: 5px;
  font-weight: 450;
  font-family: 'Ubuntu';
  margin-bottom: 25px;
}

.tcn-requerimientos {
  font-size: 22px;
  font-family: 'Ubuntu';
  color: var(--haunter-purple);
  margin-bottom: 0px;
  margin-top: 30px;
}

.tcn-description-p {
  font-weight: 400;
  font-size: 17px;
  color: $tcn-p-gray;
}

.social-networks {
  float: right;
}

.vertical-line {
  border-left: 1px solid gray;
  margin-top: 25px;
}

.other-offers-title {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  font-size: normal;
  line-height: 31px;
  color: var(--haunter-purple);
  flex: none;
  order: 0;
  flex-grow: 0;
  text-align: center;
}

.other-cargo-span {
  
  width: 195px;
  height: 25px;
  left: 0px;
  top: 0px;
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: var(--haunter-purple);
}

.other-region-p {
  color: #7C8DB0;
  font-size: 13px;
  font-weight: 400;
  font-family: 'Ubuntu';
  margin-top: 5px;
  margin-bottom: 25px;
}

.others-btn {
  margin-top: 20px;
  font-weight: bold;
  margin-bottom: 20px;
  color: $tcn-blue-login;
}
.p-color-gray {
  color: gray;
}

.upload-cv {
  font-weight: bold !important;
  padding: 11px 36px 11px 36px !important;
}

.size-13 {
  font-size: 1.2rem !important;
  font-weight: 500;
  color: #888888;
  margin-bottom: 3px !important;
}

.enviado-cv {
  margin-top: 10px;
  margin-left: 18%;
  font-weight: bold;
  color: #8cb01d;
}

.x-button {
  padding: 0px 0px 0px 10px;
  cursor: pointer;
}

.green {
  color: $tcn-breadcrumb-green;
}

.mensaje {
  font-size: 1.75rem;
  padding-bottom: 10px;
}

.mensaje-error {
  font-size: 22px;
  padding-bottom: 10px;
}

.red {
  color: $tcn-error-red
}

.redes-sociales{
  display: inline-block;
  margin-right: 5px;
}

.todas-redes-sociales{
  text-align: right;
}

.already-applied {
  font-size: 22px;
  color: $tcn-p-gray;
  font-weight: 550;
  margin-bottom: 20px;
}

.offer-aply{
  color: $tcn-color-blue;
  font-size: 27px;
  font-weight: 100 !important;
}

.notFound {
  height: 100%;
  width: 100%;
  display: inline-block;
}

.notFoundMsg {
  float: left;
  margin-left: 0px;
}

.notFoundImage {
  float: right;
  text-align: center;
}

.notFound1 {
  color: #263d49;
  margin-top: 221px;
  font-size: 42px;
  font-weight: 900;
  text-align: center;
}

.notFound2 {
  margin-top: 30px;
  font-size: 22px;
  color: #838482;
  text-align: center;
}

.notFound3 {
  margin-top: 15px;
  font-size: 20px;
  font-weight: 900;
  color: #8dc740;
  text-align: center;
}

.logo-container {
  margin-bottom: 0px;
}
