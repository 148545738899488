@import 'colors.scss';

.login-button:hover {
  color: black;
}

.registrate {
  color: white;
  background-color: #4990e0;
  padding: 8px;
  border-radius: 8px;
  border: 0px;
  padding-left: 40px;
  padding-right: 40px;
}

.flex-1{
  flex: 1;
}

.container-2{
  display: inline-block;
}

.tittle-modal-applicant {
  color: #4990e0;
  font-family: 'Roboto' !important;
  font-weight: bold;
}

.red {
  color: red;
  font-size: 14px;
}

.input-button {
  background-color: inherit !important;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}