$focus-box-shadow: 0 0 0 3px rgba(var(--t-secondary-rgb), .4);

// button
$btn-font-size-lg:     20px;

$btn-padding-y:        5px;
$btn-padding-x:        .8rem;
$btn-padding-y-sm:     0.157rem;
$btn-padding-x-sm:     .65rem;
$btn-padding-y-lg:     6px;
$btn-padding-x-lg:     1.25rem;

$btn-border-radius:    5px;
$btn-border-radius-sm: 5px;
$btn-border-radius-lg: 5px;
// TODO: radius nuevo sist. diseño
// $btn-border-radius:          40px;
// $btn-border-radius-sm:       40px;
// $btn-border-radius-lg:       40px;

$btn-active-box-shadow:      $focus-box-shadow;

$btn-disabled-opacity:        .4;

// btn-close
$btn-close-padding-x:        .25em;
$btn-close-padding-y:        $btn-close-padding-x;
$btn-close-focus-shadow:     0;

btn-success {
  color: var(--t-white) !important;
  &:hover, &:focus, &:active {
    color: var(--t-white);
  }
}

.btn-light {
  --bs-btn-color: var(--t-black);
  --bs-btn-bg: var(--t-body);
  --bs-btn-border-color: var(--t-body);

  --bs-btn-hover-color: var(--t-primary);
  --bs-btn-hover-bg: var(--blue-superman-50);
  --bs-btn-hover-border-color: transparent;

  --bs-btn-focus-shadow-rgb: 217, 218, 222;
  --bs-btn-active-color: var(--t-primary);
  --bs-btn-active-bg: var(--blue-superman-75);
  --bs-btn-active-border-color: transparent;
}

a {
  text-decoration: none;
  &.btn.btn-tonal-primary {
    color: inherit !important;
  }
}