$primary: #6344dd;// intermediate
$talana-primary: #6344dd;
$secondary: #8567FF;
$info: #39B8FF;
$success: #44D7B6;
$warning: #FFC736;
$danger: #FF3743;

$light: #FDFDFF;
$dark: #0A0716;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "info": $info,
  "success": $success,
  "warning": $warning,
  "danger": $danger,

  "light": $light,
  "dark": $dark
);

:root {
  --black: #181818;
  --gray-1: #585858;
  --gray-2: #757575;
  --gray-3: #ccc;
  --gray-4: #e5e5e5;
  --blue-black: #27273f;
  --blue-gray-90: #506083;
  --blue-gray-80: #6679a2;
  --blue-gray-70: #7c8db0;
  --blue-gray-60: #8c9bba;
  --blue-gray-50: #9daac4;
  --blue-gray-40: #adb8ce;
  --blue-gray-30: #bec6d7;
  --blue-gray-20: #ced4e1;
  --blue-gray-10: #dee3eb;
  --blue-gray-05: #f0f4f9;
  --blue-gray-01: #eff0f2;
  --blue-gray-00: #f1f1f8;
  --superman-blue: #2222bf;
  --superman-blue-75: #b5b5ff;
  --superman-blue-50: #e4e4ff;
  --superman-blue-30: #f4f4ff;
  --capitan-blue: #0081fc;
  --capitan-blue-50: #e0f0ff;
  --captain-blue: var(--capitan-blue);
  --captain-blue-50: var(--capitan-blue-50);
  --hulk-green: #00af7d;
  --hulk-green-50: #e0fff6;
  --ironman-red: #e90019;
  --ironman-red-50: #ffe1e4;
  --wolverine-yellow: #ffd000;
  --wolverine-yellow-50: #fffde8;
  --lightblue-nebula: #00c3f2;
  --lantern-green: #57fbdb;
  --scarlet-witch-pink: #fe0a73;
  --rocket-orange: #ff6d00;
  --talana-blue: var(--superman-blue);
  --font-family: "Inter","Open Sans",sans-serif;
  --haunter-purple: #6344dd;
  --gengar-purple: #8567ff;
}