.index-title {
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 31px;
  color: var(--haunter-purple);
  flex: none;
  order: 0;
  flex-grow: 0;
}

.container-title {
  margin-top: 30px;
}

.separating-line {
  margin-bottom: 20px;
}

.index-date {
  text-align: right;
  color: rgba(94, 95, 94, 1);
}

.name-offer {
  margin-top: 15px;
}

.title-to-show {
  /* Título de oferta laboral */
  position: absolute;
  /* Subtítulo 1 Medium */
  font-family: 'Ubuntu';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  /* Azul Superman */
  color: var(--haunter-purple); 
}

.offer-description {
  display: inline-block !important;  
}

@media (max-width: 800px) {
  .index-date {
    text-align: left
  }

  .name-offer {
    margin-top: 5px;
  }

  .margin-top-label {
    margin-top: 10px;
  }
}

.title-to-show {
  margin-bottom: 1px;
}

.noCompany {
  height: 100%;
  width: 100%;
}

.noCompanyMsg {
  margin-left: 0px;
}

.noCompanyImage {
  text-align: center;
  img{
    max-width: 100%;
  }
}

.noCompany1 {
  font-size: 42px;
  font-weight: 900;
  color: #263d49;
  text-align: center;
}

.noCompany2 {
  font-size: 22px;
  color: #838482;
  text-align: center;
}

.page-active a {
  z-index: 0 !important;
}

.page-active-green > a {
  background-color: var(--haunter-purple) !important;
  border-color: var(--haunter-purple) !important;
  color: white !important;
}

.pagination > li > a:hover, .pagination > li > span:hover {
  cursor: pointer;
}

.disabled > a {
  cursor: not-allowed !important;
}

.pagination > li > a, .pagination > li > span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.428571429;
  color: var(--haunter-purple);
  text-decoration: none;
  background-color: #fff;

  border: 1px solid #ddd;
}

.label-pass-forget-password{
  margin-bottom: 20px;
  cursor: pointer;
  font-size: 15px;
  background-color: transparent;
  border-color: transparent;
}

  
