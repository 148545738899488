@import 'login.scss';
@import 'applicant_register.scss';
@import 'index_offer.scss';
@import 'login_company.scss';
@import 'navbar.scss';
@import 'show_offer.scss';

@import '../styles/custom-bootstrap';

:root {
  --black: #181818;
  --gray-1: #585858;
  --gray-2: #757575;
  --gray-3: #ccc;
  --gray-4: #e5e5e5;
  --superman-blue: #2222bf;
  --superman-blue-75: #b5b5ff;
  --superman-blue-50: #e4e4ff;
  --superman-blue-30: #f4f4ff;
  --hulk-green: #00af7d;
  --hulk-green-50: #e0fff6;
  --ironman-red: #e90019;
  --ironman-red-50: #ffe1e4;
  --wolverine-yellow: #ffd000;
  --wolverine-yellow-50: #fffde8;
  --lightblue-nebula: #00c3f2;
  --lantern-green: #57fbdb;
  --scarlet-witch-pink: #fe0a73;
  --rocket-orange: #ff6d00;
  --talana-blue: var(--superman-blue);
  --haunter-purple: #6344dd;
  --gengar-purple: #8567ff;
}

html {
  background-color: var(--haunter-purple); // para mantener color en footer
  height: 100%;
}

.same-block {
  display: inline-block;
}

button > svg > path {
  fill: var(--gray-2);
}

label {
  margin-bottom: 3px;
  font-size: 14px;
}


// fix height de paginas por el footer
.container {
  min-height: 50vh;
}

@media (max-width: 500px) {
  .container {
    min-height: 80vh;
  }
}

// Fix para eliminar borde amarillo al presionar botón en Chrome
button:focus,
button.focus,
button:active.focus,
button.active.focus {
  outline: none !important;
}
