@import "~bootstrap/scss/functions";

// here goes variables customization

@import "custom-bootstrap/variables";
@import 'colors';

$link-color: $primary;

$container-max-widths: (
  sm: 640px,
  md: 960px,
  lg: 1080px,
  xl: 1366px,
  xxl: 1366px
);

$link-hover-decoration: underline !default;
$link-decoration: none !default;

@import "~bootstrap/scss/variables";

// input
$input-color: rgba(var(--t-black-rgb), .8);
$input-border-width: 0;
$input-border-radius: 8px;
$input-padding-y: 8px;
$input-padding-x: 12px;
$input-line-height: 1.25;

$input-focus-box-shadow: none;
$input-focus-border-width: 0;

$input-transition: all .2s ease-out;

// form-floating
$form-floating-height:            add(3.00rem, 4px);
$form-floating-line-height:       1.25;
$form-floating-padding-x:         $input-padding-x;
$form-floating-padding-y:         .8rem;
$form-floating-input-padding-t:   1.25rem;
$form-floating-input-padding-b:   .2rem;
$form-floating-label-pt:          .65;
$form-floating-label-opacity:     .65;
$form-floating-label-transform:   scale(.85) translateY(-.4rem) translateX(.15rem);
$form-floating-transition:        opacity .1s ease-in-out, transform .1s ease-in-out;

.form-floating > label {
  color: var(--t-gray-600);
}

@import "custom-bootstrap/button";

// load bootstrap
@import "~bootstrap/scss/bootstrap";

// Form Control
.form-control {
  outline: 1px solid rgba(10, 7, 22, 0.24);
  &:hover {
    outline: 1px solid rgba(39, 39, 63, 1);
  }
  &:focus {
    outline: 2px solid var(--t-secondary);
  }
}

// Alerts
.alert {
  align-items: center;
  border-radius: 16px;
  border: none;
  display: flex;
  font-size: 14px;
  gap: 12px;
  padding: 1rem;
  .title {
    font-size: 18px;
    font-weight: bold;
  }
  &.alert-danger {
    background-color: rgba(255, 164, 169, .8);
    color: rgba(20, 4, 3, 1);
  }
  &.alert-success {
    background-color: rgba(173, 255, 237, 1);
    color: rgba(10, 17, 15, 1);
  }
}